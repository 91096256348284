import React from "react";
import logo from "../assets/logo3.webp";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { FaArrowUp } from "react-icons/fa6";
import { animateScroll } from 'react-scroll';
import { Link, NavLink } from 'react-router-dom';


export default function Footer() {
  

  
  function onSubmit() {
    animateScroll.scrollToTop({
      duration: 100,
      smooth: 'easeInOutQuad'
    });
  }

  
  return (
    <>
      <div className="Footer py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5 col-12 ft-1 footer_div_li">
            <Link to="/home"><img className='customlogo img-fluid' src={logo}  alt="Logo" /></Link>
              <ul className="list-unstyled pt-4 text-muted fw-semibold">
                <p className="footer_p">Let's innovate <br></br>together with us</p>
                {/* <li>
                  <div className="d-flex align-items-start ">
                    <div><GrLocation className="fs-5"/></div> <div><a className="text-decoration-none text-muted"> Andromeda,123 <br />
                    Aviation Street,<br/>London,<br/>SW1A,<br/>1AA</a></div>
                  </div>
                </li>
                <li className="mt-lg-2">
                  <a className="text-decoration-none text-muted" href="tel:+12398235070">
                    <FiPhoneCall /> (239) 823 5070
                  </a>
                </li> */}
                <li className="mt-lg-2 mb-4">
                <a className="footer_email" href="mailto:contact@andromeda-sim.com">
                    <MdOutlineEmail /> contact@andromeda-sim.com
                  </a>
                </li>
                <li className="mt-lg-2">
                <div className="company_registernumber">
                  <p>Company registration number 15808914 </p>
                  <p>(Registered in England and Wales)</p>
                  </div>
                
                  
                </li>
              </ul>

              
            </div>
            <div className="d-block d-md-none px-2">
              <hr />
            </div>
            <div className="col-md-6 col-lg-3 col-12 ft-2 footer_link_heading">
              <h5>Useful Links</h5>
              <ul className="list-unstyled fw-semibold useful_link">
                
                <Link className="text-decoration-none text-dark " to='/whoisandromeda' ><li  className="py-2" >Who is Andromeda-Sim?</li></Link> 
                <Link className="text-decoration-none text-dark" to='/features'   ><li className="py-2" >Features</li></Link> 
                <Link className="text-decoration-none text-dark" to='/roadmap'><li  className="py-2" >Roadmap</li></Link> 
                <Link  className="text-decoration-none text-dark" to='/contactus'><li  className="py-2" >Contact Us</li></Link> 
                
              </ul>
            </div>
            <div className="d-block d-md-none px-2">
              <hr />
            </div>
            <div className="col-md-6 col-lg-4 col-12 footer_link_heading">
              <h5>Legal</h5>

              <ul className="list-unstyled fw-semibold useful_link">
                
                <Link  className="text-decoration-none text-dark" to='/termsandcondition' ><li  className="py-2">Terms and Conditions</li></Link>             
                <Link  className="text-decoration-none text-dark" to='/privacy' ><li  className="py-2">Privacy Policy</li></Link> 

              </ul>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between pt-4">
            
              <div className="footer-icons d-flex justify-content-center align-items-center ">
                <a href="https://www.linkedin.com/company/andromeda-sim/" className="mx-1" target="_blank">
                  <FaLinkedin className="fs-2"/>
                </a>
                <a href="https://www.facebook.com/profile.php?id=61557870732734" className="mx-1" target="_blank">
                  <FaFacebook className="fs-2"/>
                </a>
              </div>
              <div>
              <button className="btn-footer text-nowrap" onClick={onSubmit}>
              Back to Top <FaArrowUp />
                
              </button>
              </div>
          </div>
          <div>
            <p className="footer_last_phra pt-4 pt-lg-2">
              Copyright &copy;  2024 Andromeda-Sim Ltd. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
