import React, { useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import { IoCalendarClear } from "react-icons/io5";
import { IoMdCheckboxOutline } from "react-icons/io";
import { CiSettings } from "react-icons/ci";
import { LuUsers } from "react-icons/lu";
import { GoGraph } from "react-icons/go";
import image12 from "../assets/image12.webp";
import QTG from "../assets/QTG.webp";
import Maintenance from "../assets/Maintenance.webp";
import Crew from "../assets/Crew.png";
import Status from "../assets/Status.png";
import unlog from "../assets/unlog.png";
import { Link } from 'react-router-dom';

// Define your items and corresponding images
const items = [
  { name: 'Scheduler', icon: <IoCalendarClear />, image: image12 },
  { name: 'QTG Tests', icon: <IoMdCheckboxOutline />, image: QTG },
  { name: 'Maintenance', icon: <CiSettings />, image: Maintenance },
  { name: 'Crew Enroll', icon: <LuUsers />, image: Crew },
  { name: 'Simulation Status', icon: <GoGraph />, image: Status },
];

export default function Page1() {
  const [selectedItem, setSelectedItem] = useState('Scheduler');
  const [selectedImage, setSelectedImage] = useState(image12);
  const imageRef = useRef(null);

  const handleItemClick = (item, image) => {
    setSelectedItem(item);
    setSelectedImage(image);
    
    if (imageRef.current) {
      imageRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
  };

  return (
    <Container>
      <hr className="my-4 d-none d-md-block pt-2 homehr" />
      <div className='px-0 '>
        <ul className="d-flex justify-content-between list-unstyled cursor-pointer tabnav mobile-scroll">
          {items.map((item) => (
            <div key={item.name} className="">
              <li
                className={`${selectedItem === item.name ? 'pricolor' : 'text-muted'}`}
                onClick={() => handleItemClick(item.name, item.image)}
              >
                {item.icon} {item.name}
                <hr className={`custom-hr ${selectedItem === item.name ? 'pribgcolor' : 'hidden'}`} />
              </li>
            </div>
          ))}
        </ul>
      </div>
      <div className='position-relative' ref={imageRef}>
        {selectedImage && (
          <img src={selectedImage} className='img-fluid' alt='Selected Image' />
        )}
      
      

      </div>
    </Container>
  );
}
