import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../assets/logo3.webp";

export default function Header() {
  return (
    <Navbar expand="lg">
      <Container>
        <NavLink to="/home"><img className='customlogo img-fluid' src={logo}  alt="logo" /></NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-center gap-md-3 '>
          <Nav className='nav-custom gap-0 gap-md-3'>
            <NavLink className="nav-link" to="/home">Home</NavLink>
            <NavLink  className="nav-link" to="/whoisandromeda">Who is Andromeda-Sim?</NavLink>
            <NavLink  className="nav-link" to="/features">Features</NavLink>
            <NavLink  className="nav-link" to="/roadmap">RoadMap</NavLink>
            <NavLink  className="nav-link" to="/contactus">Contact Us</NavLink>
            <div className='d-block d-lg-flex nav_buttons_div d-block d-lg-none'>
            <Link  to="/requestDemo"><button className="btn-signup whitespace-nowrap">Request a DEMO</button></Link> 
            {/* <button className="btn-login">Login</button> */}
          </div>
          </Nav>
         

          
        </Navbar.Collapse>
        <div className='d-block d-lg-flex nav_buttons_div d-none d-lg-block'>
           <Link  to="/requestDemo"><button className="btn-signup whitespace-nowrap">Request a DEMO</button></Link> 
            {/* <button className="btn-login">Login</button> */}
          </div>
      </Container>
    </Navbar>
  );
}
