import React from 'react'
import Header from '../components/Header'

import Privacy from '../components/Privacy'

export default function WebPrivacy() {
  return (
    <div>
        <div className='HeaderMaindiv'><Header/></div>
        <Privacy/>
    </div>
  )
}
