import React from 'react'
import RoadMap from '../components/RoadMap'
import Header from '../components/Header'

export default function WebRoadMap() {
  return (
    <div className=''>
      <div className='HeaderMaindiv'><Header/></div>
        <RoadMap/>
    </div>
  )
}
