import React from 'react'
import Page6 from '../components/Page6'
import Header from '../components/Header'

export default function ContactUs() {
  return (
    
    <div>
     <div className='HeaderMaindiv'><Header/></div>
        <Page6/>
    </div>
  )
}
