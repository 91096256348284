import React from 'react'
import Page2 from '../components/Page2'
import Page3 from '../components/Page3'
import Page4 from '../components/Page4'
import Header from '../components/Header'
import Page2_1 from '../components/Page2_1'

export default function WhoisAndromeda() {
  return (
    <div>
      <div className='HeaderMaindiv'><Header/></div>
        <Page2/>
        <Page2_1/>
        <Page3/>
        <Page4/>
    </div>
  )
}
