import React, { useCallback, useState } from 'react'
import image3 from "../assets/image3.webp";
import { Link } from 'react-router-dom';


export default function Page2() {

    const truncateText = useCallback((text, wordLimit) => {
        const words = text.split(" ");
        if (words.length > wordLimit) {
          return words.slice(0, wordLimit).join(" ") + "...";
        }
        return text;
      }, []);

      const [showMore, setShowMore] = useState(true);
      const toggleShowMore = () => {
        setShowMore(!showMore);
      };

      const phra = `Andromeda-Sim is a leading software solutions provider specializing in monitor or control simulators quality and performance. Our cutting-edge product operates on a Software as a Service (SAAS) model and offers a versatile subscription-based framework that is precisely tailored to meet the unique needs of our valued clients. Designed to improve training center performance, our solution gives clients the ability to enlist multiple technicians under their account while maintaining complete control over the software's deployment. Elevate your aviation training and maintenance endeavors with Andromeda-Sim - a sophisticated and versatile software solution meticulously crafted with industry experts in mind.`;


  return (
    <div className='custom-container1'>
    <div className='d-flex flex-column flex-lg-row  page2'>
        <div className='col-12 col-lg-7 d-flex justify-content-center align-items-center'>
            <div className='w-100 page2phr'>
                <h1 className='pb-4'><span>Who is</span> Andromeda-Sim?</h1>
                <p onClick={toggleShowMore} className='mb-0 col-12 col-lg-11'>
                {showMore ? truncateText(phra, 20) : phra}
                </p>

                <p onClick={toggleShowMore} className='page2_phra_show'><u>{showMore ? "Show more" : "Show less"}</u></p>

                <Link to="/requestDemo"><button className='btn-home1 col-12 col-md-4 mt-2 mt-md-4'>Request a DEMO</button></Link> 
            </div>
        </div>

        <div className='col-12 col-lg-5 d-none d-md-block ' >
            <img src={image3} className=" h-100 w-100 image-new-page2" alt="Who is Andromeda" />
        </div>
        <div className='col-12 col-lg-5 px-4 pb-4 d-block d-md-none'>
            <img src={image3} className="rounded-4 img-fluid" alt="Who is Andromeda" />
        </div>
    </div>
</div>


  )
}
