import React, { useCallback, useState } from 'react'
import image3 from "../assets/page2new.webp";
import image3mobile from "../assets/page2newmobile.webp";
import QuoteIcon from "../assets/QuoteIcon.svg";

import { Link } from 'react-router-dom';


export default function Page2_1() {

  return (
    <div className='custom-container_new_page2'>
    <div className='d-flex flex-column flex-lg-row'>
        <div className='pt-4 pt-md-0 d-flex justify-content-center align-items-center page2newbgimgdiv'>
            <div className='col-12 col-lg-8 w-100 newpage2phr'>
                <img src={QuoteIcon}  className='QuoteIcon'></img>
                <h1 className='pt-2 pb-3'>Meet Our Expert</h1>
                <p className='col-12 col-lg-10'>At the heart of Andromeda-SIM Alfredo Celso is our <span>expert consultant</span>, a dedicated Flight Simulation Engineer. He spearheads our sales efforts and manages all client interactions, bringing his extensive knowledge and experience to every project</p>
                


                <Link to="/contactus"><button className='btn-home1new col-12 col-md-4 mt-4 mb-3 mb-md-5'>Contact Now</button></Link> 
            </div>
        </div>
        <div className='col-12 col-lg-4 position-relative'>
            <img src={image3} className="img-fluid d-none d-lg-block  h-100 w-100 image-new-page2 rounded-2" alt="Andromeda" />
            <img src={image3mobile} className="img-fluid d-block d-lg-none" alt="Andromeda" />
            <div className='position-absolute bottom-0 end-0 text-center me-3 d-none d-md-block'>
            <p className='Alfredo_name'>Alfredo Celso</p>
            
                <p className='Engineer_position'>Flight Simulation Engineer</p>
        
            </div>

            <div className='position-absolute bottom-0 start-50 translate-middle-x text-center  d-block d-md-none'>
            <p className='Alfredo_name'>Alfredo Celso</p>
            
                <p className='Engineer_position text-nowrap'>Flight Simulation Engineer</p>
        
            </div>
        </div>
    </div>
</div>


  )
}
