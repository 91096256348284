import React from 'react'
import Page5 from '../components/Page5'
import Header from '../components/Header'

export default function Features() {
  return (
    <div>
      <div className='HeaderMaindiv'><Header/></div>
        <Page5/>
    </div>
  )
}
