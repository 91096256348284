import React, { useState } from "react";
import Header from "./Header";
import { Modal } from "react-bootstrap";
import axios from "axios";
import celebration from "../assets/successgif.gif";
import './modalContent.css';

export default function RequestDemo() {
  const [formData, setFormData] = useState({
    yourname: "",
    emailAddress: "",
    company: "",
    telephone: "",
  });

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const response = await axios.post(
        `https://www.andromeda-sim.com/emailapi/requestdemo_email.php`,
        formData
      );
      setShowModal(true);
      console.log("response.data", response.data);
      setFormData({
        yourname: "",
        emailAddress: "",
        company: "",
        telephone: "",
      });
    } catch (error) {
      console.error("Failed to send email:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleContinue = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className="requestDemoHeaderBgImg mb-4">

        <div className=" container d-flex flex-column align-items-center justify-content-center">
          <div className=" d-flex flex-column align-items-center justify-content-center">
            <div className="col-12 col-lg-6 text-center pt-4 pt-lg-5 ">
              <h1 className="pricolor1">Request a DEMO</h1>
              <p className="text-justify pricolor fs-5">
                Take the first step towards enhancing your training and
                maintenance center experience by requesting a demo today.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" container d-flex flex-column align-items-center justify-content-center pt-2">
        <div className=" d-flex flex-column align-items-center justify-content-center col-12 col-lg-5">
          <form className="col-12 pb-5 px-2" action="https://api.web3forms.com/submit" method="POST"> {/* onSubmit={handleSubmit} */}
          <input type="hidden" name="access_key" value="24c4a5b9-e815-4e5b-b11e-7d7ca389010d"></input> {/*new line */}
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Your Name<span className="text-danger"> *</span>
              </label>
              <input
                type="text"
                className="form-control border bg-light  shadow-sm py-2  "
                id="yourname"
                name="yourname"
                value={formData.yourname}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email Address<span className="text-danger"> *</span>
              </label>
              <input
                type="email"
                className="form-control border bg-light shadow-sm py-2"
                id="emailAddress"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleChange}
                required
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                title="Please enter a valid email address."
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Company
              </label>
              <input
                type="text"
                className="form-control border bg-light shadow-sm py-2"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
              <input
                type="tel"
                className="form-control border bg-light shadow-sm py-2"
                id="telephone"
                name="telephone"
                value={formData.telephone}
                onChange={handleChange}
                required
              />
            </div>

            <div className="py-4">
              <button type="submit" className="btn-signup w-100" disabled={loading}>
              {loading ? "Sending..." : "Submit Request"}
                
              </button>
            </div>
          </form>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <h2 className="text-center pt-4 box_content_heading">Request sent!</h2>
        <Modal.Body>
          <div className="box_content px-2">
          <div  className="d-flex col-12 align-items-center justify-content-center"> <img src={celebration} style={{width: "110px", height: "110px"}} alt="Celebration"/></div>
            <p className="phra">
            Thank you for reaching out to us! We understand your time is valuable, and we promise to get back to you within 3-4 business days.
            </p>
            
          </div>
        </Modal.Body>
        <div className="px-4 pt-3 pb-4">
          <button type="submit" className="col-12 box_btn">Done</button>  {/**onClick={handleContinue} */}
        </div>
      </Modal>
    </div>
  );
}
