import React from 'react';
import image8 from "../assets/image8.webp";
import image9 from "../assets/image9.webp";


export default function Page4() {
  return (
    <div className='container py-5 '>   
         <div className='px-1 andro_meda'>
            <h1 className='fw-bold pb-5'><span>Andro</span>meda-Sim users</h1>
            
          </div>   
        <div className='row position-relative d-flex flex-column flex-lg-row' >
            <div className='col position-relative my-3'> 
            <img src={image8} className="rounded-4 img-fluid"  alt='Train pilots'/> 
            <div className="image-name-overlay">Train pilots </div>
            </div>
            <div className='col position-relative my-3' > 
            <img src={image9} className="rounded-4 img-fluid" alt='Maintain, repair and configure issues' />
            <div className="image-name-overlay">Maintain, repair and configure issues </div>  
            </div>
        </div>
      
    </div>
  );
}
