import React from 'react'
import Header from '../components/Header'

import Privacy from '../components/Privacy'
import Terms from '../components/Terms'

export default function WebTerms() {
  return (
    <div>
        <div className='HeaderMaindiv'><Header/></div>
        <Terms/>
    </div>
  )
}
