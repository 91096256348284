import React from 'react'
import Header from '../components/Header'
import RequestDemo from '../components/RequestDemo'

export default function WebRequestDemo() {
  return (
    <div className='requestDemoBgImg'>
        <div className='HeaderMaindiv'><Header/></div>
        <RequestDemo/>
    </div>
  )
}
